<template>
    <b-row class="match-height">
      <b-col
lg="8"
offset="2"
> <b-card
      title="Purchase Order Receiving By Warehouse Detail"
    >
        <b-row>
          <b-col cols="12">
            <b-button
              type="button"
              variant="primary"
              class="mr-1"
              @click="previous()"
            >
              <feather-icon
                icon="ArrowLeftIcon"
                size="16"
                class="align-middle"
              />
              Back
            </b-button>
            <b-button
              type="button"
              variant="primary"
              class="mr-1 float-rigth"
              @click="print()"
            >
              <feather-icon icon="PrinterIcon" size="16" class="align-middle" />
              Print
            </b-button>
            <div class="devider" />
            </b-col>
        <b-col cols="12">
        <b-form-group>
          <label for="no_invoice">Items :</label>
        </b-form-group>
        <b-form-group>
          <br>
           <div >
          <b-row>
            <b-col
cols="6"
class="text-center font-weight-bold"
>
              Item name
            </b-col>
            <b-col
cols="2"
class="text-center font-weight-bold"
>
              Unit name
            </b-col>
            <b-col
cols="2"
class="text-center font-weight-bold"
>
              Shelf
            </b-col>
            <b-col
cols="2"
class="text-center font-weight-bold"
>
              Received
            </b-col>
            </b-row>
           <p />
          <b-row
v-for="(item) in items"
:key="item.purchase_order_warehouse_receive_detail_item_id"
>
          <br>
            <b-col
cols="6"
class="text-center"
>
                <b-list-group>
                  <b-list-group-item href="#">
{{ item.item.item_name }} ({{ item.item.item_code }})
</b-list-group-item>
                </b-list-group>
              </b-col><b-col
cols="2"
class="text-center"
>
                <b-list-group>
                  <b-list-group-item href="#">
{{ item.item.unit ? capitalizeWords(item.item.unit.unit_name) : '-' }}
</b-list-group-item>
                </b-list-group>
              </b-col>
            <b-col
cols="2"
class="text-center"
>
            <b-form-input
              v-model="item.purchase_order_warehouse_receive_detail_item_shelf"
              class="text-center"
              readonly
/>
            </b-col>
            <b-col
cols="2"
class="text-center"
>
            <b-form-input
              v-model="item.purchase_order_warehouse_receive_detail_item_received"
              class="text-center"
              readonly
/>
            </b-col>
            <b-col cols="12">
              <br>
            </b-col>
            </b-row>
            </div>
        </b-form-group>
          </b-col>
        </b-row>
    </b-card>
      </b-col>
    </b-row>
  </template>
  <script>
  import {
    BRow, BCol, BFormGroup, BFormInput, BForm, BButton, BCard, BListGroup, BListGroupItem,
    } from 'bootstrap-vue'
  import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
  import axios from 'axios'
  import moment from 'moment'
  import Ripple from 'vue-ripple-directive'
  import { VueHorizontalTimeline } from "vue-horizontal-timeline"
import { ref } from '@vue/composition-api'

  const VersionCheck = ''
  const items = []
  const chosenItem = ref(3)
  const chosens = []
  const statusItems = []
  const supplier = ''
  const shipmenBy = ''
  const deliveryAddress = ''
  const purchaseOrderSchedule = ''
  const billingAddress = ''
  const termOfPayment = ''
  const paymentType = ''
  const type = ''
  const purchaseOrderByWarehouseIsConfirmed = false
  const purchaseOrderLink = ''
  const purchaseOrderDiscountPercentage = ''
  export default {
    components: {
      VueHorizontalTimeline,
      BListGroup,
      BListGroupItem,
      BCard,
      BRow,
      BCol,
      BFormGroup,
      BFormInput,
      BForm,
      BButton,
    },
    directives: {
      Ripple,
    },
    data() {
      return {
        type,
        purchaseOrderLink,
        purchaseOrderDiscountPercentage,
      purchaseOrderByWarehouseIsConfirmed,
      paymentType,
      statusItems,
      VersionCheck,
      items,
      chosenItem,
      chosens,
      supplier,
      shipmenBy,
      deliveryAddress,
      purchaseOrderSchedule,
      billingAddress,
      termOfPayment,
      }
    },
    mounted() {
        this.getDetail()
    },
    methods: {
    getDetail() {
      const userToken = this.$cookies.get("userToken")
const headers = {
  "Content-Type": "application/json",
  Authorization: `Bearer ${userToken}`,
}
      const item = JSON.parse(localStorage.getItem('PurchaseOrderWarehouseDetail'))
      const id = item.purchase_order_warehouse_receive_id
      axios
      .get(`${process.env.VUE_APP_API_URL}${process.env.VUE_APP_API_GET_PURCHASE_ORDER_RECEIVE_WAREHOUSE_DETAILS}/${id}`, { headers })
      .then(response => {
        if (response.data.success === true) {
          this.items = response.data.data
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Fetching Data success',
                icon: 'CheckIcon',
                variant: 'success',
              },
            })
        } else {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Fetching Data failed',
                icon: 'CheckIcon',
                variant: 'danger',
              },
            })
        }
        })
    },
    print() {
      const userToken = this.$cookies.get("userToken")
const headers = {
  "Content-Type": "application/json",
  Authorization: `Bearer ${userToken}`,
}
      const item = JSON.parse(localStorage.getItem('PurchaseOrderWarehouseDetail'))
      const id = item.purchase_order_warehouse_receive_id
      axios
      .get(`${process.env.VUE_APP_API_URL}${process.env.VUE_APP_API_GET_PURCHASE_ORDER_RECEIVE_WAREHOUSE_DOWNLOAD}/${id}`, { headers })
      .then(response => {
        if (response.data.success === true) {
          const url = `${process.env.VUE_APP_API_URL}temp/${response.data.data}`
          window.open(url, '_blank')
        } else {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Download Data failed',
                icon: 'CheckIcon',
                variant: 'danger',
              },
            })
        }
        })
    },
      getOptionLabel(option) {
        return (option && option.label) || ''
      },
      putItem(val) {
        if (this.chosens.length > 0) {
          let same = false
          this.chosens.map(elem => {
                if (elem.value === val.value) {
                  same = true
                }
          })
          if (same === false) {
              let item = null
              this.items.map(elem => {
                if (elem.value === val.value) {
                  item = { label: elem.label, value: elem.value, qty: 1 }
                }
              })
            this.chosens.push(item)
          }
        } else {
              let item = null
              this.items.map(elem => {
                if (elem.value === val.value) {
                  item = { label: elem.label, value: elem.value, qty: 1 }
                }
              })

            this.chosens.push(item)
        }
      },
      applyItem(val) {
        console.log(val.data)
      },
      getOptionKey(option) {
        return (option && option.value) || ''
      },
      inputSearch(search) {
        console.log(search)
      },
      removeRow(val) {
        this.chosens = this.chosens.filter(obj => (obj.value !== val))
      },
      dateFormat(value, format = 'MM/DD/YYYY') {
          return moment(String(value)).format(format)
      },
    dateSimple(value, format = 'YYYY-MM-DD') {
        let dateRet = ''
        if (value !== null) {
          dateRet = moment(String(value)).format(format)
        } else { dateRet = null }
        return dateRet
    },
    previous() {
          this.$router.push({ name: 'apps-purchase-order-pre-receive-warehouse' })
    },
      numberFormat(value) {
        return parseFloat(value).toFixed(2)
      },
    capitalizeWords(str) {
    // Split the string into words using spaces as the separator
    const words = str.split(' ')
    // Capitalize the first letter of each word and join them back together
    const capitalizedWords = words.map(word => {
        return word.charAt(0).toUpperCase() + word.slice(1)
    })
    // Join the capitalized words into a single string with spaces
    return capitalizedWords.join(' ')
    },
    },
  }
  </script>